
/* General Styles */

.row {
  width: 90%;
	max-width: 1360px;
	margin-left: auto;
	margin-right: auto;
	display: flex;
	flex-flow: row wrap;
}

.row.expanded {
	max-width: 100%;
  width: 100%;
}

body{
  font-family: lato;
}

h1, h2, h3, h4, h5, h6{
  color: $brand;
  font-family: 'Bitter';
}

p, ul, ol{
  color: #505050;
}

.button{
  text-transform: uppercase;
  padding: 1em 1.7em;
  font-size: .8rem;
}


/* - Top Bar Styles - */

#header{
  background-color: #fff;
}
#header .sticky{
  background-color: #fff;
}
.sticky {
  transform: none;
  z-index: 3;
}
#header .is-stuck{
  box-shadow: 0 0 10px rgba(0,0,0,.2);
}
.header-left.column {
	padding: 1.5em 0px;
  background-color: #fff;
}
.header-right .menu li a{
  color: #fff;
  text-transform: uppercase;
  font-size: 0.9em;

}

.menu-icon::after {
	background: #000;
	box-shadow: 0 7px 0 #000, 0 14px 0 #000;
}
.logo{
  float: left;
}

#header .row .row{
  margin: auto;
}

.header-row-outer{
  position: relative;
  &::after{
    content: '';
    position: absolute;
    width: 50%;
    top: 0;
    right: 0;
    bottom: 0;
    background-color: $brand;
    z-index: -1;
  }
}



.menu-button {
	position: absolute;
	right: 2%;
	top: 50%;
	transform: translateY(-50%);
}

.button.toggle {
  margin: 0px;
}

.panel-relative {
	position: relative;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	height: 100%;
}

.simple-panel {
	position: fixed;
	height: 100%;
	width: 400px;
	right: 0;
	top: 0;
	background: $brand;
	list-style-type: none;
	padding: 1em;
	z-index: 9999;
	transform: translateX(100%);
	-webkit-transition: transform cubic-bezier(1, 0.21, 0, 1.07) 0.45s;
	-moz-transition: transform cubic-bezier(1, 0.21, 0, 1.07) 0.45s;
	transition: transform cubic-bezier(1, 0.21, 0.26, 0.98) 0.45s;
	box-shadow: none;
}

.simple-panel.is-active {
  box-shadow: 0.01em 0 0.5em #737373;
  transform: translateX(0);
}

.simple-panel li {
	color: #fff;
	display: block;
	padding: 1rem 1.5rem;
}

.simple-panel a {
	color: #fff;
	display: block;
	padding: 1rem 1.5rem;
}
.panel-menu {
	position: absolute;
	top: 10%;
	left: 0;
	right: 0;
	margin: 0 auto;
	text-align: center;
}

.content-block{
  .row{
    padding: 5% 0%;
  }
}

.content-book-link {
	background-color: #1d1d1d;
	padding: 1.3em 1.8em;
  text-align: center;
  p{
    color: #fff;
    font-size: 1.2em;
    font-weight: lighter;
    font-style: italic;
    margin: 0px;
  }
  a{
    color: #fff;
    font-weight: 500;
	  margin-left: 25px;
  }
}

#testimonials{
  background-image: url('../../images/testimonials-bg.png');
  background-position: top;
  padding: 4em 0em;
  ul{
    list-style-type: none;
    li{
      p{
        font-size: 1.3em;
        font-weight: lighter;
        color: #fff;
        font-style: italic;

          margin: 1em 0em 0em 0em;
        }
      }
      hr{
        display: inline-block;
        border: none;
        height: 5px;
        width: 40px;
        background-color: #fff;

      }
      p strong{
        font-size: 1.1em;
        font-weight: 500;
        font-family: 'Bitter';
        font-style: normal;
      }
    }
  }


#home-content-2{
  ul{
    list-style-type: none;
    margin: 1.5em 0em;
    padding: 0px;
    li{
      	margin: 0.4em 0em;
      &::before{
        content: '\f013';
        font-family: 'FontAwesome';
        margin-right: 10px;
        color: $brand;
      }
    }
  }
}

#home-content-3{
  background-color: #f0f0f0;
  background-image: url('../../images/other-service-bg.png');
  background-position: bottom left;
  background-repeat: no-repeat;
  background-size: 24%;
  padding-bottom: 4em;
  h4{
    color: #262525;
  }
  ul{
    list-style-type: none;
    margin: 1.5em 0em;
    padding: 0px;
    li{
      float: left;
      width: 50%;
      margin: 0.4em 0em;
      &::before{
        content: '\f013';
        font-family: 'FontAwesome';
        margin-right: 10px;
        color: #cecece;
      }
    }
  }
}


#home-contact-form{
  background-image: url('../../images/home-form-bg.png');
  h2{
    color: #fff;
  }
  .contact-form{
    .input-group {
    	color: #fff;
      margin-bottom: 2.5rem;
      input[type="text"], textarea{
        background: transparent;
        border: none;
        border-bottom: solid 2px #fff;
        box-shadow: none;
        color: #fff;
        margin-top: 8px;
        -webkit-transition: 0.3s;
        -moz-transition: 0.3s;
        transition: 0.3s;
        &:focus{
          border-bottom: solid 2px $brand;
        }
      }
    }
  }
}


footer{
  background-color: #343434;

  i{
    color: #fff;
  }
  a{
    color: #fff;
  }
  h5{
    color: #fff;
  }
  p{
    color: #6a6a6a;
  }
}

.footer-top{
  padding: 3em 0em;
}

.footer-bot{
  padding-bottom: 1.5em;
}

.footer-social{
  a{
    margin-left: 10px;
  }
}


/* - Scroll to top - */

.scrollup {
	width: 40px;
	height: 40px;
	position: fixed;
	bottom: 30px;
	right: 30px;
	display: none;
	/* text-indent: -9999px; */
	background-color: #FFF;
	box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
	color: #0A0A0A;
	line-height: 40px;
	text-align: center;
	font-size: 20px;
	font-weight: bold;
}


/* - Slideshow Styles - */

#slideshow{
  position: relative;
}

.slideshow-scrolldown {
	width: auto !important;
	margin-top: 3em;
	margin-left: 1em;
  cursor: pointer;
  padding: 3px;
}

.slideshow-overlay-bot{
  position: absolute;
  bottom: 1em;
  left: 0;
  right: 0;
  z-index: 2;
  .slideshow-overlay-phone{
    p{
      color: #fff;
      font-style: italic;
    }
  }
  .slideshow-overlay-social{
    i{
      color: #fff;
      font-size: 1.7em;
      margin-left: 10px;
    }
  }
}

.slide {
	position: relative;
    img{
    width: 100%;
    height: auto;
    }
}

.owl-item{
	-webkit-backface-visibility: hidden;
  -webkit-transform: translateZ(0) scale(1.0, 1.0);
}

.overlay {
	position: absolute;
	top: 50%;
	left: 0;
	transform: translateY(-50%);
	right: 0;
	margin: 0 auto;
}

.slideshow-text{
  h3, h4{
    color: #fff;
  }
  h4{
    font-family: lato;
  }
  h3 {
	  font-size: 2.5em;
  }
}

.slideshow-mot{
    background-color: $brand;
    padding: 2em;
    img{
        float: left;
    }
    h4{
        color: #fff;
    }
    p{
        color: #fff;
        font-weight: lighter;
    }
    img {
    	width: auto !important;
    	height: auto;
    	margin-right: 15px;
    }
    a{
        color: #fff;
        display: inline-block;
        text-transform: uppercase;
        &::after{
            content: '→';
            display: inline-block;
            margin-left: 1em;
        }
    }
}


/* Styles for Testimonial Slider */

.testimonial-slider{
  ul{
    margin: 0px;
    padding: 0px;
  }
    .owl-dots{
        text-align: center;
    }
    .owl-dot{
      display: inline-block;
      width: 8px;
      height: 8px;
      margin-right: 10px;
      background-color: rgba(10, 10, 10, 0.29);
      border-radius: 50%;
        &:last-of-type{
          margin-right: 0px;
        }
        &.active{
          background-color: $brand;
        }
    }
}


/* Social Media Icons */

.social .fa{
  color: #fff;
}

.social .fa-circle{
  color: #000;
}

.facebook-icon .fa-circle{
  color: #3b5998;
}

.twitter-icon .fa-circle{
  color: #55ACEE;
}

.youtube-icon .fa-circle{
  color: #cd201f;
}

.linkedin-icon .fa-circle{
  color: #0077b5;
}

.social a:hover .fa-circle{
  color: $brand;
}


/* Contact Form Styles */

.input-group {
	display: block;
}

.mobile-menu-wrap{

  .close-button{
    padding: 0.5em;
    position: absolute;
    top: 1rem;
    right: 1rem;
    line-height: 21px;
    span{
      color: #fff;
    	font-size: 2.5rem;
    	font-weight: lighter;
    }
  }
}

.menu-btn-wrap{
    display: none;
}


/* ------------Max-width-media----------- */
@media screen and (max-width: 1300px) {
  .header-row-outer::after{
    width: 64%;
  }

}

@media screen and (max-width: 1024px) {

    .menu-btn-wrap{
        display: block;
    }


}

@media screen and (max-width: 1024px) {

    .mobile-menu-wrap{
      position: fixed;
      height: 100%;
      width: 400px;
      right: 0;
      top: 0;
      background: $brand;
      padding: 2em;
      z-index: 9999;
      transform: translateX(100%);
      -webkit-transition: transform cubic-bezier(1,.21,0,1.07) .45s;
      -moz-transition: transform cubic-bezier(1,.21,0,1.07) .45s;
      transition: transform cubic-bezier(1,.21,.26,.98) .45s;
      box-shadow: none;
      ul{
        margin-top: 3em;
      }
      ul li a{
        color: #fff;
        padding: 1em;
        font-size: 1.1em;
      }
      &.is-active{
        transform: translateX(0);
      }
    }

    .mobile-menu-wrap{
        .menu.vertical{
            flex-wrap: wrap;
            li{
                flex: 0 0 100%;
            }
        }
        .close-button{
            display: block !important;
        }
    }



  .footer-social{
    visibility: hidden;
  }

  .slideshow-mot{
    padding: 1em;
    img{
        margin-bottom: 15px;
    }
    h4{
        font-size: 1.2em;
    }
    p{
        display: none;
    }
  }



  .footer-top > .small-12:last-of-type{
    display: none;
  }

  #home-content-1{
    .content-book-wrap{
      margin-bottom: 10%;
    }
  }

  #home-content-2{
    .columns{
      &:last-of-type{
        margin-top: 3em;
      }
    }
  }

  #home-content-3{
    background-image: none;
    padding-bottom: 0px;
  }

  .header-row-outer::after{
    display: none;
  }

  .slideshow-scrolldown{
    display: none !important;
  }

  .slideshow-text{
    h4{
      display: none;
    }
  }




  .gallery-wrap {
  	width: 85%;
  	margin: 0 auto;
  }

  .gallery-main{
    padding: 0px;
  }

  .gallery-wrap .owl-carousel.gallery-thumbs{
    display: none;
  }

  .gallery-main .owl-prev{
    top: auto;
    bottom: 0;
    left: 15px;
    font-size: 27px;
    height: 40px;
    width: 40px;
    line-height: 38px;
  }

  .gallery-main .owl-next{
    top: auto;
    bottom: 0;
    right: 15px;
    font-size: 27px;
    height: 40px;
    width: 40px;
    line-height: 38px;
  }
}

@media screen and (max-width: 40em) {

  #testimonials li p {
  	font-size: 1.1em;
  }

  #testimonials li p strong {
	  font-size: 1em;
  }

  #home-content-3 ul li {
  	float: none;
  	width: auto;
  	margin: .4em 0;
  }

  .content-block .row {
    padding: 10% 0;
  }


  .slideshow-overlay-bot{
    display: none;
  }

  /* - Adds padding to Columns with the .mobile-padding class on small screen sizes - */
  .mobile-padding{
    padding: 0% 6%;
  }

  .simple-panel {
    width: 100%;
  }

}


@media screen and (max-width: 500px) {

  .slideshow-text h3 {
  	font-size: 1.5em;
  }

  .mobile-menu-wrap{
    width: 100%;
  }

}


/* ------------Min-width-media----------- */

@media screen and (min-width: 40em) {
	/* Fixes header on scroll for screens above mobile */
	.fixed{
	  position: fixed;
	  top: 0px;
		z-index: 2;
		width: 100%;
	}

}
